<template>
  <div class="product-emission-detail">
    <page-title-action
      :title="pageTitle"
      :isFixedButton="isFixedButton"
      :workflowData="emissionsDetail"
      isRegisterEmission
      :isDisableUpdateApprovedEmissionData="isDisableUpdateApprovedData"
      :isHasData="isCheckChangeData"
      @handleActionByName="handleActionByName"
    />
    <div class="product-information">
      <title-bar :title="$t('cfp_emissions_product.label_information_product')"> </title-bar>
      <product-info
        :declaredUnitList="declaredUnitList"
        :productInfo="emissionsDetail"
        :isReadOnly="true"
        :isDetailProduct="true"
      />
    </div>
    <div class="product-information boundary">
      <title-bar :title="$t('cfp_emissions_product.detail_product_emissions')">
        <div class="title-mode">
          <div class="title-mode_label">{{ $t('new_cfp.label_select_type') }}</div>
          <new-button-custom
            class="title-mode_btn card-type"
            :class="selectedMode === 1 && 'colored'"
            :label="$t('new_cfp.button_card_type')"
            @action="selectedMode = 1"
          />
          <new-button-custom
            class="title-mode_btn list-type"
            :label="$t('new_cfp.button_list_type')"
            :class="selectedMode === 2 && 'colored'"
            @action="selectedMode = 2"
          />
        </div>
      </title-bar>
      <new-boundary
        :dataList="emissionsDetail.boundaries"
        :isDetailProduct="true"
        :workflowData="emissionsDetail"
        :isDisableInputProductData="true"
        :isBoundaryForEmission="true"
        :selectedMode="selectedMode"
        @handleApprovedProductPopup="handleApprovedProductPopup"
        @export="onExportProcessBoundaryHandler"
      />
    </div>
    <div v-if="isMobile" ref="scrollElement">
      <page-title-action
        :title="pageTitle"
        :isFixedButton="isFixedButton"
        :workflowData="emissionsDetail"
        isRegisterEmission
        :isDisableUpdateApprovedEmissionData="isDisableUpdateApprovedData"
        hiddenIfDoNotHaveAnyButton
        @handleActionByName="handleActionByName"
      />
    </div>
    <notification-popup
      v-if="dialog.notification"
      :dialog="dialog.notification"
      :message="message.notification"
      :confirmText="confirmText"
      @submit="handleCloseDialogByType('notification')"
    />
    <notification-popup
      v-if="dialog.notificationSaved"
      :dialog="dialog.notificationSaved"
      :confirmText="confirmText"
      :message="message.savedChange"
      @submit="handleCloseNotificationSaved"
    />
    <notification-popup
      v-if="dialog.submitSuccess"
      :dialog="dialog.submitSuccess"
      :message="message.submitSuccess"
      :confirmText="confirmText"
      @submit="handleClosePopupSubmitSuccess"
      classes="register"
    />
    <submit-data-popup
      v-if="dialog.submit"
      :dialog="dialog.submit"
      :listApprovers="listApprovers"
      :isLoadingSubmitting="isLoadingSubmitting"
      :isShowTitle="false"
      title=""
      @closeDialogSubmitData="handleCloseDialogByType('submit')"
      @submitHandler="handleSubmitEmissions"
    />
    <question-popup
      v-if="dialog.question"
      :dialog="dialog.question"
      :message="message.question"
      :isSaveButtonDelete="isDeleteProduct"
      :confirmText="$t('popup.button_delete')"
      @close="handleCloseDialogByType('question')"
      @submit="handleDeleteEmissionsProduct()"
    />
    <notification-popup
      v-if="dialog.error"
      :dialog="dialog.error"
      :message="message.error"
      :isErrorImg="true"
      @submit="handleCloseDialogByType('error')"
    />
    <approval-popup
      v-if="dialog.questionApprove"
      :dialog="dialog.questionApprove"
      :items="listStatusApproval"
      :confirmText="$t('b_register_product.button_decision')"
      @submit="approvalDataHandler"
      @close="handleCloseDialogByType('questionApprove')"
    />
    <notification-popup
      v-if="dialog.approvedData"
      :dialog="dialog.approvedData"
      :message="message.approvedData"
      @submit="handleClosePopupApprovedData"
      classes="register"
    />
    <notification-popup
      v-if="dialog.cancleSuccess"
      :dialog="dialog.cancleSuccess"
      :message="message.messageCancleSuccess"
      classes="register"
      @submit="handleClosePopupCancleSuccess"
    />
    <question-popup
      v-if="dialog.cancleSubmit"
      :dialog="dialog.cancleSubmit"
      :confirm="true"
      :closeText="$t('popup.button_cancel')"
      :confirmText="$t('popup.button_cancel_request')"
      :message="message.cancelSubmit"
      classes="register"
      @close="handleCloseDialogByType('cancleSubmit')"
      @submit="submitCancelRequest()"
    />
    <accept-cancel-popup
      v-if="dialog.acceptCancel"
      :dialog="dialog.acceptCancel"
      :items="listStatusCancel"
      :confirmText="$t('b_register_product.button_decision')"
      @submit="handleAcceptRequestCancelSubmit"
      @close="handleCloseDialogByType('acceptCancel')"
    />
    <!-- case noti accept cancle -->
    <notification-popup
      v-if="dialog.acceptCancleSuccess"
      :dialog="dialog.acceptCancleSuccess"
      :message="message.messageAcceptCancle"
      classes="register"
      @submit="handleClosePopupAcceptCancleSuccess"
    />

    <!-- dialogUpdateApprovalData -->
    <question-popup
      v-if="dialog.confirmEditApprovedData"
      :dialog="dialog.confirmEditApprovedData"
      :confirm="true"
      :closeText="$t('popup.button_close')"
      :message="message.updateApprovalData"
      :confirmText="$t('b_register_product.button_keep')"
      @close="handleCloseDialogByType('confirmEditApprovedData')"
      classes="register"
      @submit="clickSaveDraftEmissionsBtn({})"
    />

    <error-popup
      v-if="errDetail.isShowErr"
      :dialog="errDetail.isShowErr"
      :message="errDetail.message"
      @submit="handleBackProductListApproval"
    />
    <question-popup
      v-if="dialog.redirectToApprovedProduct"
      :dialog="dialog.redirectToApprovedProduct"
      :message="message.redirectToApprovedProduct"
      :confirmText="$t('new_cfp.button_confirm_move_to_approved_product')"
      :isSaveButtonDelete="true"
      @close="dialog.redirectToApprovedProduct = false"
      @submit="handleClickRedirectToApprovedProduct()"
    />
  </div>
</template>
<script>
// UI import
import ProductInfo from '@/components/products/ProductInfo';
import TitleBar from '@/components/products/table/TitleBar';
import PageTitleAction from '@/components/products/common/page-title-action.vue';
import NotificationPopup from '@/components/dialogs/notification-popup';
import SubmitDataPopup from '@/components/dialogs/ActionHeaderTable/submit-data-popup';
import QuestionPopup from '@/components/dialogs/question-popup';
import ApprovalPopup from '@/components/dialogs/approval-popup';
import AcceptCancelPopup from '@/components/dialogs/accept-cancel-popup';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import NewBoundary from '@/components/products/NewBoundary';
import NewButtonCustom from '@/components/commonApp/ButtonCustom/NewButton';
//logic import
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import {
  actionEmissionsProduct,
  emissionsProductWorkflow,
  deleteEmissionsProduct,
  actionNotificationEmissionProduct,
  actionNotificationProduct,
  getExportReportProduct
} from '@/api/product/emissionsProduct';
import { mapState, mapGetters, mapActions } from 'vuex';
import {
  STATUS_SUBMIT,
  STATUS_RETURN,
  STATUS_REPRENSENT_RETURN,
  STATUS_FIELD,
  STATUS_CANCEL_SUBMMITED,
} from '@/constants/status';
import { ACTION_HEADER_TABLE, APPROVAL_TYPE, CANCEL_TYPE } from '@/constants/registerData';
import { ROUTES } from '@/router/constants';
import i18n from '@/lang/i18n';
import { ROLE } from '@/constants/role';
import { getUnitListApi } from '@/api/product';
import { getCustomBoundaryForState } from '@/utils/cfpEmissions';


export default {
  components: {
    ProductInfo,
    TitleBar,
    PageTitleAction,
    NotificationPopup,
    SubmitDataPopup,
    QuestionPopup,
    ApprovalPopup,
    AcceptCancelPopup,
    ErrorPopup,
    NewBoundary,
    NewButtonCustom,
  },
  data() {
    return {
      isMobile: window.innerWidth < 1024,
      isFixedButton: true,
      emissionsDetail: {},
      declaredUnitList: [],
      dialog: {
        notification: false,
        notificationSaved: false,
        error: false,
        question: false,
        submit: false,
        questionApprove: false,
        cancleSubmit: false,
        submitSuccess: false,
        acceptCancel: false,
        acceptCancleSuccess: false,
        cancleSuccess: false,
        approvedData: false,
        confirmEditApprovedData: false,
        redirectToApprovedProduct: false,
      },
      message: {
        notification: '',
        error: '',
        question: '',
        savedChange: this.$t('facility_management.message_saved_changes'),
        approve: this.$t('register_data.popup_message_update_approved_data'),
        cancelSubmit: this.$t('popup.popup_cancel_approve_product'),
        submitSuccess: this.$t('register_data.popup_message_submit_sucess'),
        messageAcceptCancle: '',
        messageCancleSuccess: this.$t('popup.button_cancel_approve_product'),
        approvedData: '',
        updateApprovalData: this.$t('register_data.popup_message_update_approved_data'),
        redirectToApprovedProduct: this.$t('new_cfp.popup_redirect_to_approved_product'),
      },
      isSubmitToMyseft: false,
      isDeleteProduct: false,
      listStatusApproval: [],
      oldNoteOfEmission: null,
      listStatusCancel: [
        {
          id: CANCEL_TYPE.ACCEPT,
          name: this.$t('register_data.radio_accept_cancel_submit'),
          description: this.$t('register_data.description_accept_cancel_submit'),
        },
        {
          id: CANCEL_TYPE.REJECT,
          name: this.$t('register_data.radio_reject_cancel_submit'),
          description: this.$t('register_data.description_reject_cancel_submit'),
        },
      ],
      errDetail: {
        isShowErr: false,
        message: '',
      },
      selectedMode: 1,
      approvedProductPopupId: null,
    };
  },
  computed: {
    ...mapState('approval', ['listApprovers']),
    ...mapState('product', ['previousRoute']),
    ...mapState('userData', ['currentUser']),
    ...mapGetters('newRegisterData', ['isLoadingSubmitting']),
    isApprovedData() {
      return [STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(
        this.emissionsDetail?.status,
      );
    },
    pageTitle() {
      return this.$t('cfp_emissions_product.register_product_emissions');
    },
    confirmText() {
      return i18n.locale !== 'ja' ? this.$t('list_menu.label_decision') : 'OK';
    },
    isRegisterEmissions() {
      return this.emissionsDetail?.is_tmp === 1;
    },
    isDisableUpdateApprovedData() {
      return this.isApprovedData && this.oldNoteOfEmission === this.emissionsDetail?.note;
    },
    isCheckChangeData() {
      if (this.emissionsDetail?.is_tmp === 1) {
        if (this.emissionsDetail.note || this.emissionsDetail.product_emissions !== null) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    currentUserRoleId() {
      return this.currentUser?.user?.role_id;
    },
    isShowApprovedProductPopup() {
      return (
        this.isRegisterEmissions ||
        ([STATUS_FIELD.STATUS_DRAFT, STATUS_FIELD.STATUS_REPRENSENT_RETURN, STATUS_FIELD.STATUS_RETURN].includes(
          this.emissionsDetail?.status,
        ) &&
          this.emissionsDetail?.is_tmp === 0 &&
          this.emissionsDetail?.is_owner === 1) ||
        (this.currentUserRoleId === ROLE.ADMIN && this.isApprovedData)
      );
    },
  },
  created() {
    // show popup duplicate productr success
    if (this.$route.query.isDuplicate === 'true') {
      this.dialog.notification = true;
      this.message.notification = this.$t('cfp_emissions_product.message_duplicate_product_emissions');
      const newQuery = {
        ...this.$route.query,
      };
      delete newQuery.isDuplicate;
      this.$router.push({
        name: 'EmissionsDetailProduct',
        params: { emissionId: this.$route?.params?.emissionId },
        query: newQuery,
      });
    }
  },
  async mounted() {
    document.addEventListener('scroll', this.updateActionPosition);
    if (window.innerWidth < 1024) {
      this.isFixedButton = true;
    }
    this.getListUnit();
    window.addEventListener('resize', this.handleResize);
    // get emissions detail
    if (this.$route?.params?.emissionId) {
      await Promise.all([this.getApproversList(), this.getEmissionsDetail(this.$route?.params?.emissionId)]);
      this.updateBreadCrumb(this.breadCrumb());
    }
    if (this.$route.query?.is_allow_cancel) {
      // handle cancel submit
      await this.handleApprovalCancleSubmit();
    }
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoading']),
    ...mapActions('approval', ['getApproversList']),
    ...mapActions('product', ['actionUpdateNotification']),
    breadCrumb() {
      const breadCrumb = [
        {
          text: this.$t('b_list_submited.hyperlink_home'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
      ];
      const typeDetail = this.$route.query?.type;
      if (typeDetail === 'detail') {
        breadCrumb.push({
          text: this.$t('cfp_emissions_product.title_list_products'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        });
      } else if (typeDetail === 'submit') {
        breadCrumb.push({
          text: this.$t('b_list_submited.label_application_status'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_SUBMITTED_LIST}`,
        });
      } else if (typeDetail === 'approval') {
        breadCrumb.push({
          text: this.$t('left_menu.button_approval'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_APPROVED_LIST}`,
        });
      }
      breadCrumb.push(
        {
          text: this.$t('cfp_emissions_product.hyperlink_product_name', { productName: this.emissionsDetail?.name }), //TODO: update text
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/product-detail/${this.emissionsDetail?.product_id}?type=${typeDetail}`,
        },
        {
          text: this.$t('cfp_emissions_product.discharge_details_registration'),
          disabled: true,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
      );

      return breadCrumb;
    },
    handleResize() {
      this.isMobile = window.innerWidth < 1024;
    },
    handleShowError(errors) {
      this.message.error = Object.keys(errors.errors)
        .map((key) => errors.errors[key]?.join('\n'))
        .join('\n');
      this.dialog.error = true;
    },
    updateActionPosition() {
      const scrollElementTop = this.$refs.scrollElement?.offsetTop; // Vị trí của phần tử
      const scrollPosition = window.scrollY + window.innerHeight; // Vị trí scroll hiện tại
      if (scrollPosition > scrollElementTop + 215) {
        // scrollElementTop + 215 ===  the space between the buttons with top screen
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
    },
    async getEmissionsDetail(id) {
      try {
        const response = await actionEmissionsProduct('get', id);
        this.oldNoteOfEmission = response.data?.note;
        const branchData = response.data.branch || response.data;
        this.emissionsDetail = {
          ...response.data,
          unit_price_actual: response.data.unit_price,
          periodic_quantity_actual: response.data.periodic_quantity,
          boundaries: getCustomBoundaryForState(response.data.boundaries),
          declareUnit: response.data.declaration_unit,
          startDate: response.data.start_date,
          endDate: response.data.end_date,
          lotNumber: response.data.lot_number,
          unit: response.data.product_unit,
          product_emissions: response.data.product_emissions ?? null,
          declare_emissions: response.data.declare_emissions ?? null,
          product_emissions_unit: `kg-CO2e/${response.data.product_unit}`,
          declare_emissions_unit:
            response.data?.declaration_unit === null
              ? `kg-CO2e/${this.$t('cfp_emissions_product.error_not_set')}`
              : `kg-CO2e/${response.data?.declaration_unit}`,
          created_at: response.data.is_tmp === 1 ? '' : formatDateTimeDataTable(response.data.created_at),
          organizational_division: branchData?.organizational_division,
          company_name: branchData?.company_name,
          business_name: branchData?.business_name,
          country: branchData?.country,
          layer_3: branchData?.layer_3,
          layer_4: branchData?.layer_4,
          layer_5: branchData?.layer_5,
          layer_6: branchData?.layer_6,
          branch_id: response.data?.branch?.id || null,
          is_reuse: response.data.is_reuse ? 1 : 0,
        };
        if (response.data?.unread_flg) {
          this.handleUpdateNotification(this.emissionsDetail);
        }
      } catch (err) {
        if (this.$route?.query?.type === 'approval') {
          if (err.status === 403) {
            this.errDetail.isShowErr = true;
            this.errDetail.message = err.message;
          } else {
            this.$router.push({ name: 'ProductsApprovalNew' }).catch(() => {});
          }
        } else if (this.$route?.query?.type === 'submit') {
          if (err.status === 403) {
            this.errDetail.isShowErr = true;
            this.errDetail.message = err.message;
          } else {
            this.$router.push({ name: 'ProductsListSubmittedNew' }).catch(() => {});
          }
        } else {
          const newQuery = { ...this.$route.query };
          delete newQuery?.type;
          this.$router.push({ name: 'ProductsEmissionNew', query: newQuery }).catch(() => {});
        }
      }
    },
    handleUpdateNotification(dataWorkFollow) {
      if (
        (dataWorkFollow.is_approver === 1 &&
          [STATUS_SUBMIT, STATUS_CANCEL_SUBMMITED].includes(dataWorkFollow.status)) || // cal API if user is approver
        (dataWorkFollow.is_owner === 1 && [STATUS_RETURN, STATUS_REPRENSENT_RETURN].includes(dataWorkFollow.status))
      ) {
        if (
          [STATUS_SUBMIT, STATUS_RETURN, STATUS_REPRENSENT_RETURN, STATUS_CANCEL_SUBMMITED].includes(
            dataWorkFollow.status,
          )
        ) {
          const params = {
            id: dataWorkFollow.id,
          };
          actionNotificationEmissionProduct({ ...params }, 'put').then(() => {
            actionNotificationProduct({}, 'get').then((res) => {
              this.actionUpdateNotification(res.data);
            });
          });
        }
      }
    },
    handleCloseNotificationSaved() {
      this.dialog.notificationSaved = false;
      if (!this.isRegisterEmissions) {
        location.reload();
      }
    },
    handleActionByName(nameAction) {
      switch (nameAction) {
        case 'clickSaveDraftEmissionsBtn':
          if (this.isApprovedData) {
            // case edit data approved
            this.dialog.confirmEditApprovedData = true;
          } else {
            this.clickSaveDraftEmissionsBtn({});
          }
          break;
        case 'clickCancelSaveDraftEmissionsBtn':
          this.showPopupDeleteEmissionProduct();
          break;
        case 'clickSubmitProductBtn':
          this.handleShowPopupSubmitEmissions();
          break;
        case 'clickDuplicateEmissionsBtn':
          this.handleDuplicateEmissionsBtn();
          break;
        case 'clickApproveEmissionBtn':
          this.handleShowPopupApprover();
          break;
        case 'clickCancelSubmitEmissionBtn':
          this.handleCancelSubmit();
          break;
        case 'clickAllowCancelSubmitEmissionBtn':
          this.handleShowAlowCancelSubmitPopup();
          break;
        default:
          break;
      }
    },
    handleShowAlowCancelSubmitPopup() {
      this.dialog.acceptCancel = true;
    },
    handleCancelSubmit() {
      this.dialog.cancleSubmit = true;
    },
    handleClosePopupAcceptCancleSuccess() {
      this.dialog.acceptCancleSuccess = false;
      let params = {};
      if (this.previousRoute[this.currentUser?.user.contractor_id] === 'DetailProduct') {
        params = {
          name: 'DetailProduct',
          params: { id: this.emissionsDetail?.product_id },
          query: this.$route.query,
        };
      } else {
        params = { name: 'ProductsApprovalNew' };
      }
      this.$router.push(params).catch(() => {});
    },
    async handleAcceptRequestCancelSubmit(acceptCancelFormData) {
      try {
        this.dialog.acceptCancel = false;
        let is_allow_cancel = null;
        if (acceptCancelFormData.status === CANCEL_TYPE.ACCEPT) {
          is_allow_cancel = CANCEL_TYPE.ACCEPT;
        } else {
          is_allow_cancel = CANCEL_TYPE.REJECT;
        }
        const payload = {
          action: ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
          data: {
            id: this.emissionsDetail?.id,
            comment:
              acceptCancelFormData?.comment?.length > 255
                ? acceptCancelFormData.comment.substr(0, 255)
                : acceptCancelFormData.comment,
          },
        };

        await emissionsProductWorkflow(payload, 'put', { is_allow_cancel: is_allow_cancel });
        // show popup for user
        this.dialog.acceptCancleSuccess = true;
        this.message.messageAcceptCancle =
          acceptCancelFormData.status === CANCEL_TYPE.ACCEPT
            ? this.$t('register_data.popup_message_accept_cancel_approve')
            : this.$t('register_data.popup_message_accept_cancel_reject');
      } catch (error) {
        this.handleShowError(error);
      }
    },
    async submitCancelRequest() {
      try {
        this.dialog.cancleSubmit = false;
        const payload = {
          action: ACTION_HEADER_TABLE.CANCEL_SUBMITTED,
          data: { id: this.emissionsDetail.id },
        };
        await emissionsProductWorkflow(payload, 'put');
        this.dialog.cancleSuccess = true;
      } catch (error) {
        this.dialog.cancleSubmit = false;
        this.handleShowError(error);
      }
    },
    handleClosePopupCancleSuccess() {
      this.dialog.cancleSuccess = false;
      let params = {};
      if (this.previousRoute[this.currentUser?.user.contractor_id] === 'DetailProduct') {
        params = {
          name: 'DetailProduct',
          params: { id: this.emissionsDetail?.product_id },
          query: this.$route.query,
        };
      } else {
        params = { name: 'ProductsEmissionNew' };
      }
      this.$router.push(params).catch(() => {});
    },
    getProductEmissionsPayload() {
      const response = {
        id: this.emissionsDetail.id,
        note: this.emissionsDetail?.note?.slice(0, 255),
        product_emissions: this.emissionsDetail?.product_emissions,
        declare_emissions: this.emissionsDetail?.declare_emissions,
      };
      return response;
    },
    getBoundaryEmissionsPayload() {
      const response = this.emissionsDetail.boundaries;
      return response;
    },
    async clickSaveDraftEmissionsBtn(dataProps) {
      try {
        const { redirectToApprovedProduct = false } = dataProps;
        const payload = {
          action: this.isRegisterEmissions ? ACTION_HEADER_TABLE.SAVE_DRAFT : ACTION_HEADER_TABLE.UPDATE,
          data: {
            product_emission: this.getProductEmissionsPayload(),
            boundaries: this.getBoundaryEmissionsPayload(),
          },
        };
        this.dialog.confirmEditApprovedData = false;
        await emissionsProductWorkflow(payload);
        if (redirectToApprovedProduct) {
          this.redirectToApprovedProduct();
        } else if (!this.isRegisterEmissions) {
          this.dialog.notificationSaved = true;
        } else {
          window.location.reload();
        }
      } catch (err) {
        this.handleShowError(err);
      }
    },
    async handleDuplicateEmissionsBtn() {
       this.actionUpdateIsLoading(true);
      try {
        const response = await actionEmissionsProduct('put', this.emissionsDetail.id);
        const newQuery = {
          ...this.$route.query,
          isDuplicate: 'true',
        };
        await this.$router
          .replace({ name: 'EmissionsDetailProduct', params: { emissionId: response.id }, query: newQuery })
          .catch(() => {});
        this.actionUpdateIsLoading(false);
        window.location.reload();
      } catch (err) {
        this.handleShowError(err);
      }
    },
    handleShowPopupSubmitEmissions() {
      this.dialog.submit = true;
    },
    async handleSubmitEmissions(approvalFata) {
      try {
        const payload = {
          action: ACTION_HEADER_TABLE.SUBMIT_DATA,
          data: {
            product_emission: {
              ...this.getProductEmissionsPayload(),
              selected_approver: approvalFata?.selected_approver,
              comment: approvalFata?.comment,
              title: approvalFata.title,
            },
            boundaries: this.getBoundaryEmissionsPayload(),
          },
        };
        const responeData = await emissionsProductWorkflow(payload);
        this.isSubmitToMyseft = responeData?.data?.is_auto_approve ? true : false; //TODO: update consiton when api update
        this.dialog.submit = false;
        this.dialog.submitSuccess = true;
      } catch (err) {
        this.dialog.submit = false;
        this.handleShowError(err);
      }
    },
    handleClosePopupSubmitSuccess() {
      this.dialog.submitSuccess = false;
      this.$router
        .push({ name: 'DetailProduct', params: { id: this.emissionsDetail?.product_id }, query: this.$route.query })
        .catch(() => {});
    },
    showPopupDeleteEmissionProduct() {
      this.dialog.question = true;
      this.message.question = this.$t('cfp_emissions_product.message_delete_product');
      this.isDeleteProduct = true;
    },
    //todo: update type by query, update api, logic
    async handleDeleteEmissionsProduct() {
      this.actionUpdateIsLoading(true);
      try {
        const newQuery = { ...this.$route.query };
        if (!this.emissionsDetail?.id) {
          this.$router
            .push({ name: 'DetailProduct', params: { id: this.emissionsDetail?.product_id }, query: newQuery })
            .catch(() => {});
          return;
        }
        await deleteEmissionsProduct(this.emissionsDetail.id);
        this.$router
          .push({ name: 'DetailProduct', params: { id: this.emissionsDetail?.product_id }, query: newQuery })
          .catch(() => {});
        this.actionUpdateIsLoading(false);
      } catch (error) {
        this.handleShowError(error);
      }
    },
    handleCloseDialogByType(type) {
      this.dialog[type] = false;
    },
    handleShowPopupApprover() {
      this.handleGetDescriptionPopupByWorkflow();
      this.dialog.questionApprove = true;
    },
    handleGetDescriptionPopupByWorkflow() {
      if (this.emissionsDetail?.is_approver === 1) {
        //TODO: update condition by workflowData
        this.listStatusApproval = [
          {
            id: APPROVAL_TYPE.APPROVE,
            name: this.$t('register_data.radio_approve'),
            description: this.$t('register_data.description_approve'),
          },
          {
            id: APPROVAL_TYPE.REJECT,
            name: this.$t('register_data.radio_reject'),
            description: this.$t('register_data.description_reject'),
          },
        ];
      } else {
        this.listStatusApproval = [
          {
            id: APPROVAL_TYPE.APPROVE,
            name: this.$t('register_data.radio_proxy_approve'),
            description: this.$t('register_data.description_proxy_approve'),
          },
          {
            id: APPROVAL_TYPE.REJECT,
            name: this.$t('register_data.radio_proxy_reject'),
            description: this.$t('register_data.description_proxy_approve'),
          },
        ];
      }
    },
    handleGetStatusApprovalForPayload(status, isApproval) {
      let response = null;
      if (status === APPROVAL_TYPE.APPROVE) {
        response = isApproval ? STATUS_FIELD.STATUS_APPROVED : STATUS_FIELD.STATUS_REPRENSENT_APPROVED;
      } else {
        response = isApproval ? STATUS_FIELD.STATUS_RETURN : STATUS_FIELD.STATUS_REPRENSENT_RETURN;
      }
      return response;
    },
    async approvalDataHandler(approvalFormData) {
      try {
        this.dialogApprovalData = false;
        const status = this.handleGetStatusApprovalForPayload(
          approvalFormData.status,
          this.emissionsDetail?.is_approver === 1,
        );
        const payload = {
          action: ACTION_HEADER_TABLE.APPROVED_DATA,
          data: {
            id: this.emissionsDetail?.id,
            status: status,
            comment:
              approvalFormData?.comment?.length > 255
                ? approvalFormData.comment.substr(0, 255)
                : approvalFormData.comment,
          },
        };
        await emissionsProductWorkflow(payload);
        this.dialog.questionApprove = false;
        this.message.approvedData = this.getMessApprovedFromStatus(status);
        this.dialog.approvedData = true;
      } catch (err) {
        this.dialog.questionApprove = false;
        this.dialogApprovalData = false;
        this.handleShowError(err);
      }
    },
    getMessApprovedFromStatus(status) {
      let message = '';
      if (status === STATUS_FIELD.STATUS_APPROVED) {
        message = this.$t('register_data.popup_message_accept_approve');
      } else if (status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED) {
        message = this.$t('register_data.popup_message_accept_approver_approve');
      } else if (status === STATUS_FIELD.STATUS_RETURN) {
        message = this.$t('register_data.popup_message_return_approve');
      } else {
        message = this.$t('register_data.popup_message_return_approver_approve');
      }
      return message;
    },
    handleClosePopupApprovedData() {
      let params = {};
      if (this.previousRoute[this.currentUser?.user.contractor_id] === 'DetailProduct') {
        params = {
          name: 'DetailProduct',
          params: { id: this.emissionsDetail?.product_id },
          query: this.$route.query,
        };
      } else {
        params = { name: 'ProductsApprovalNew' };
      }
      this.$router.push(params).catch(() => {});
    },
    async handleApprovalCancleSubmit() {
      try {
        const payload = {
          action: ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
          data: {
            id: this.emissionsDetail?.id,
          },
        };
        await emissionsProductWorkflow(payload, 'put', { is_allow_cancel: CANCEL_TYPE.ACCEPT });
        const newQuery = { ...this.$route.query };
        delete newQuery.is_allow_cancel;
        this.$router
          .push({ name: 'DetailProduct', params: { id: this.emissionsDetail?.product_id }, query: newQuery })
          .catch(() => {});
      } catch (error) {
        // handle redirect when can not approval cancle submit
        // role user 4 => list all
        // role approval 5 => list approval
        const currentRole = this.currentUser.user.role_id;
        const nameToRedirectByRole = currentRole === ROLE.USER ? 'ProductsEmissionNew' : 'ProductsApprovalNew';
        this.$router.push({ name: nameToRedirectByRole }).catch(() => {});
      }
    },
    handleBackProductListApproval() {
      this.errDetail.isShowErr = false;
      this.errDetail.message = '';
      if (this.$route?.query?.type === 'approval') {
        this.$router.push({ name: 'ProductsApprovalNew' }).catch(() => {});
      } else {
        this.$router.push({ name: 'ProductsListSubmittedNew' }).catch(() => {});
      }
    },
    async getListUnit() {
      try {
        const listUnit = await getUnitListApi();
        listUnit?.data?.forEach((item) => {
          this.declaredUnitList.push({
            key: item.key,
            value: item.name,
          });
        });
      } catch (error) {
        console.warn(error);
      }
    },
    handleApprovedProductPopup(product) {
      this.approvedProductPopupId = product?.ref_cfp_product_id;
      // handle show popup with status are STATUS_DRAFT, STATUS_RETURN, STATUS_REPRENSENT_RETURN OR REGISTER EMISSION
      if (this.isShowApprovedProductPopup) {
        this.dialog.redirectToApprovedProduct = true;
      } else {
        this.redirectToApprovedProduct();
      }
    },
    handleClickRedirectToApprovedProduct() {
      this.dialog.redirectToApprovedProduct = false;
      if (this.isApproval) {
        this.redirectToApprovedProduct();
      } else {
        this.clickSaveDraftEmissionsBtn({ redirectToApprovedProduct: true });
      }
    },
    redirectToApprovedProduct() {
      // handle for case link to approved product
      const newQuery = {
        ...this.$route.query,
        type: 'detail',
      };
      this.$router.push({
        name: 'DetailProduct',
        params: { id: this.approvedProductPopupId },
        query: newQuery,
      });
    },
    async onExportProcessBoundaryHandler(id) {
      try {
        const templateRes = await getExportReportProduct(id);
        const fileName = templateRes.fileName;
        const url = window.URL.createObjectURL(
          new Blob([templateRes.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('error:', error);
      }
      this.dialogExportProcessPopup = false;
    },
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.updateActionPosition);
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.product-emission-detail {
  .product-information {
    margin: 0 -20px;
    &.boundary {
      margin-bottom: 80px;
    }
    @include desktop {
      margin: 0;
    }
  }
}
.title-mode {
  display: flex;
  align-items: center;
  align-self: stretch;
  &_label {
    color: $monoWhite;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.42px;
    padding-right: 10px;
  }
  &_btn {
    height: 28px !important;
    box-sizing: border-box;
    width: 75px !important;
    &.card-type {
      border-radius: 4px 0px 0px 4px;
    }
    &.list-type {
      border-radius: 0px 4px 4px 0px;
    }
  }
}
</style>
